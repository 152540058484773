.bgimg {
  background-image: url(../images/impact/ctabg.png);
  background-position: center;
  background-size: cover;
  padding: 80px 0px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.ctacontainer{
  max-width: 1280px;
  margin: 0 auto 100px auto;
  padding: 0 20px !important;
}
.bgimg div{
  max-width: 900px;
  margin: 0 auto;
  padding: 0 40px !important;
}

.bgimg div h2{
  color: white;
}
.bgimg div p{
  color: white;
}

.donationtrackerpadding{
  padding-top: 120px;
  padding-bottom: 20px;
}


.btn-white{
background-color: white;
}

@media screen and (max-width: 480px) {
  .bgimg{
    padding: 40px 0px;
  }
}