.timelineSection {
  max-width: 1440px; /* Set the max width to 1200px */
  padding-top: 50px;
  padding-bottom: 140px;
  margin: 0 auto;
}

.timelineHeader {
  margin-bottom: 80px;
}

.timelineHeader h2 {
  font-size: 48px;
  max-width: 800px;
  margin: auto;
}

.timelineLine {
  height: 4px;
  width: 3960px!important;
  /* min-width: 100%; */
}

.glowTop {
  top: 240px;
  left: 50%;
  transform: translateX(-50%);
}

.glowBottom {
  top: 99px !important;
  left: 50%;
  transform: translateX(-50%);
}

.timelineDate {
  margin-top: 180px;
}

.timelineContent {
  background-color: #2a8b44;
  height: 7rem;
  margin-top: 140px;
  padding-top: 15px;
}

.timelineContentReverse {
  background-color: #2a8b44;
  height: 7rem;
  margin-top: 3spx;
  padding-top: 15px;
}

.dateReverse {
  margin-top: 160px;
}

.whiteConnector {
  top: 156px;
  border: 4px solid white;
  transform: translateY(-50%);
}

.circleConnector {
  width: 20px;
  height: 20px;
  border: 6px solid #16a349;
  background-color: white;
}

.descriptionContainer {
  margin-top: 80px;
}

.timelineWrapper {
  display: block;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  overflow-x: scroll;  /* Asegúrate de que el contenido pueda desplazarse horizontalmente */
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


/* Style for the timeline wrapper */
@media screen and (max-width: 1280px) {


  /* Hide scrollbar for WebKit-based browsers */
  .timelineWrapper::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 680px) {
 .glowTop img{
  width: 80px;
 }
 .glowBottom img {
  width: 80px;
}
.glowBottom {
  top: 70px !important;
}
.glowTop {
  top: 165px !important;
}
.timelineContent {
  height: 5rem;
  margin-top: 100px;
  padding-top: 15px;
}
.timelineContentReverse {
  height: 5rem;
  margin-top: 0px;
  padding-top: 15px;
}
.timelineDate {
  margin-top: 100px;
}
.dateReverse {
  margin-top: 100px;
}
.timelineHeader h2 {
  font-size: 32px !important;
}
}
