.theme-btn {
  background-color: $theme-primary-color;
  line-height: 0.66em;
  color: $white;
  padding: 15px;
  border: 0;
  @include rounded-border(0);
  text-transform: uppercase;

  &:hover,
  &:focus,
  &:active {
    background-color: darken($theme-primary-color, 5%);
    color: $white;
  }

  @include media-query(767px) {
    padding: 12px;
    font-size: 13px;
    font-size: calc-rem-value(13);
  }
}

.theme-btn-s1 {
  background-color: transparent;
  font-size: 17px;
  font-size: calc-rem-value(17);
  border: 2px solid $white;
  position: relative;
  padding: 13px 20px 13px 45px;
  border-radius: 5px;
  display: inline-block;
  font-weight: 700;
  color: $white;

  i {
    position: absolute;
    left: 20px;
    top: 13px;
  }

  .fi:before {
    font-size: 15px;
    font-size: calc-rem-value(15);
  }

  &:hover {
    color: $theme-primary-color;
    border-color: $theme-primary-color;
  }
}

.theme-btn-s2 {
  font-family: $heading-font;
  font-size: 18px;
  font-size: calc-rem-value(18);
  font-weight: bold;
  color: $white;
  padding-left: 60px;
  position: relative;

  @include media-query(767px) {
    padding-left: 0;
  }

  &:before {
    content: "";
    background-color: $white;
    width: 44px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 52%;

    @include media-query(767px) {
      display: none;
    }
  }

  i:before {
    font-size: 15px;
    font-size: calc-rem-value(15);
    position: relative;
    top: 1px;
    display: inline-block;
    padding-left: 4px;
  }

  &:hover {
    color: $theme-primary-color;
  }
}

.theme-btn-s3 {
  @extend .theme-btn-s1;
  color: $theme-primary-color;
  border-color: $theme-primary-color;

  .fi:before {
    color: $theme-primary-color;
  }
}

.theme-btn-s4 {
  @extend .theme-btn-s2;
  color: $heading-color;

  &:before {
    background-color: $heading-color;
  }
}

.theme-btn-s5 {
  @extend .theme-btn-s2;
  color: $theme-primary-color;
  padding-left: 0;

  &:before {
    display: none;
  }
}

.theme-btn-s6 {
  @extend .theme-btn-s3;
  background-color: $heading-color;
  color: $white;
  border-color: $heading-color;

  .fi:before {
    color: $white;
    @include transition-time(0.3s);
  }

  &:hover .fi:before {
    color: $white;
  }

  &:hover {
    background-color: $theme-primary-color;
    color: $white;
    border-color: $theme-primary-color;
  }
}

.theme-btn-s7 {
  @extend .theme-btn-s1;

  background-color: $theme-primary-color;
  border-color: $theme-primary-color;
  color: $white;

  &:hover {
    background-color: darken($theme-primary-color, 5%);
    color: $white;
  }
}

.theme-btn-s8 {
  font-family: $heading-font;
  background-color: $theme-primary-color;
  font-size: 16px;
  font-size: calc-rem-value(16);
  position: relative;
  padding: 17px 30px;
  border-radius: 0;
  display: inline-block;
  font-weight: 600;
  color: $white;

  &:hover {
    background-color: $white;
    color: $theme-primary-color;
  }
}

.theme-btn-s9 {
  @extend.theme-btn-s8;
  background-color: $white;
  color: $theme-primary-color;

  &:hover {
    background-color: $theme-primary-color;
    color: $white;
  }
}

.theme-btn-s10 {
  @extend .theme-btn-s8;
  border: 0;
  outline: 0;
  @include transition-time(0.3s);

  &:hover {
    background-color: darken($theme-primary-color, 5%);
    color: $white;
  }
}

.view-cart-btn {
  background: $theme-primary-color;
  width: 140px;
  padding: 10px;
  margin-top: 20px;
  color: white;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: darken($theme-primary-color, 10%);
    color: $white;
  }
}
