/*--------------------------------------------------------------
	#home-style-3
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#3.1	feature-section-s2
--------------------------------------------------------------*/
.feature-section-s2 {
	@include media-query(991px) {
		padding-top: 100px;
	}

	@include media-query(767px) {
		padding-top: 80px;
	}

	.feature-grids {
		margin: 0 -15px;

		@include widther(992px) {
			margin-top: -50px;
		}

		@include media-query(767px) {
			margin: 0 -7px;
		}

		.grid {
			width: calc(33.33% - 30px);
			text-align: center;
			float: left;
			margin: 0 15px 30px;
			padding: 54px 25px 60px;

			@include widther(992px) {
				position: relative;
				top: 70px;
				@include transition-time(0.5s);
			}

			@include media-query(991px) {
				width: calc(33.33% - 30px);
				padding: 44px 20px 50px;
				box-shadow: 0px 5px 41.61px 15.39px rgba(195, 195, 195, 0.21);
			}

			@include media-query(767px) {
				width: calc(50% - 15px);
				margin: 0 7px 15px;
			}

			@include media-query(600px) {
				width: calc(100% - 15px);
			}
		}

		.grid div {

			@include widther(992px) {
				position: relative;
				top: 30px;
				@include transition-time(0.5s);
				opacity: 0;
				visibility: hidden;
			}
		}

		>.grid:nth-child(2) {
			background-color: $white;
			box-shadow: 0px 5px 41.61px 15.39px rgba(195, 195, 195, 0.21);
			top: 0;

			p {
				height: auto;
			}

			div {
				opacity: 1;
				visibility: visible;
				;
				top: 0;
			}
		}

		.grid:hover {
			background-color: $white;
			top: 0;
			box-shadow: 0px 5px 41.61px 15.39px rgba(195, 195, 195, 0.21);
		}

		.grid:hover div {
			top: 0;
			opacity: 1;
			visibility: visible;
		}

		.fi:before {
			font-size: 70px;
			font-size: calc-rem-value(70);
			color: $theme-primary-color;

			@include media-query(991px) {
				font-size: 50px;
				font-size: calc-rem-value(50);
			}

			@include media-query(767px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
			}
		}

		h3 {
			font-size: 22px;
			font-size: calc-rem-value(22);
			margin: 0 0 1em;

			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
			}
		}

		p {
			margin: 0;

			@include media-query(991px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}
		}

	}
}


/*--------------------------------------------------------------
#3.2	about-s3-section
--------------------------------------------------------------*/
.about-s3-section {
	padding-top: 90px;

	@include media-query(991px) {
		padding-top: 70px;
	}

	@include media-query(767px) {
		padding-top: 65px;
	}

	.section-title {
		margin-bottom: 50px;

		@include media-query(991px) {
			margin-bottom: 20px;
		}
	}

	.about-text-area {
		padding-top: 45px;

		@include media-query(1199px) {
			padding-top: 0;
		}

		@include media-query(991px) {
			margin: 40px 0 0;
		}

		p {
			margin-bottom: 1.5em;
		}

		h5 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			margin: 0 0 0.5em;
		}

		h5+span {
			font-size: 13px;
			font-size: calc-rem-value(13);
		}
	}

	@include media-query(991px) {
		.img-holder {
			margin-top: 50px;
		}
	}
}



/*--------------------------------------------------------------
#3.3	campain-section
--------------------------------------------------------------*/
.campain-section {
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 120px;
	overflow: hidden;

	@include media-query(991px) {
		margin-bottom: 65px;
	}

	@include media-query(767px) {
		margin-bottom: 45px;
	}

	.left-col {
		width: calc(50% + 75px);
		float: left;
		padding-bottom: 127px;

		@include media-query(991px) {
			width: 100%;
			float: none;
			padding-bottom: 50px;
		}

		.campain-area {
			max-width: 660px;
			margin-left: auto;
			padding-left: 15px;
			position: relative;
			z-index: 1;

			@include media-query(991px) {
				max-width: 100%;
				padding-right: 15px;
			}
		}
	}

	.right-col {
		width: calc(50% - 75px);
		float: right;
		position: relative;
		height: 900px;
		background-size: cover;
		background-position: center;


		@include media-query(991px) {
			float: none;
			width: 100%;
			height: 800px;
		}

		@include media-query(575px) {
			height: 500px;
		}

		>div {
			width: calc(100% + 60px);
			height: 100%;
			position: absolute;
			left: -60px;
			top: 0;
		}
	}

	.campain-slider {
		padding-top: 30px;

		@include media-query(991px) {
			padding-top: 0;
		}

		.slick-slide {
			margin: 20px 27px;
		}


		/* the parent */
		.slick-list {
			margin: -20px -27px;
		}

		.item {
			background-color: $white;
			padding: 50px 60px;
			box-shadow: 0px 5px 15.61px 5.39px rgba(195, 195, 195, 0.21);
			margin-bottom: 30px;

			@include media-query(767px) {
				padding: 30px 25px;
			}
		}

		h3 {
			font-size: 24px;
			font-size: calc-rem-value(24);
			margin: 0 0 0.5em;

			@include media-query(767px) {
				font-size: 22px;
				font-size: calc-rem-value(22);
			}
		}

		h3 a {
			color: $heading-color;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			font-size: 15px;
			font-size: calc-rem-value(15);
			margin: 0 0 1em;
		}

		.goal-raised {
			font-size: 15px;
			font-size: calc-rem-value(15);
			font-weight: 600;
			color: $theme-primary-color;
			text-transform: uppercase;
			overflow: hidden;

			>div {
				width: 50%;
				float: left;
			}

			span {
				color: $heading-color;
			}
		}

		.progress {
			background-color: #e6e6e6;
			height: 8px;
			margin-top: 30px;
			box-shadow: none;
			overflow: visible;
			box-shadow: none;
			border-radius: 10px;
			@include transition-time(0.5s);

			span {
				background-color: $theme-primary-color;
				font-size: 14px;
				font-size: calc-rem-value(14);
				font-weight: bold;
				color: $white;
				padding: 2px 6px;
				text-align: center;
				position: absolute;
				right: -15px;
				top: -32px;

				&:before {
					content: "";
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 10px solid $theme-primary-color;
					position: absolute;
					bottom: -6px;
					left: 14px;
				}

				@include media-query(991px) {
					padding: 0 6px;
				}
			}
		}

		.progress-bar {
			background: $theme-primary-color;
			position: relative;
			box-shadow: none;
			border-radius: 10px;
			overflow: visible;
		}
	}

	.dontate-now {
		font-family: $heading-font;
		font-size: 16px;
		font-size: calc-rem-value(16);
		color: $theme-primary-color;
	}
}


/*--------------------------------------------------------------
#3.4	cta-section-s3
--------------------------------------------------------------*/
.cta-section-s3 {
	@include background-style("../../images/cta-s3-bg.jpg", center center, cover, no-repeat, local);
	padding: 127px 0;
	text-align: center;

	@include media-query(991px) {
		padding: 100px 0;
	}

	@include media-query(767px) {
		padding: 80px 0;
	}

	.content-area {
		max-width: 750px;
		margin: 0 auto;
	}

	h3 {
		font-size: 48px;
		font-size: calc-rem-value(48);
		color: $white;
		margin: 0 0 1em;

		@include media-query(991px) {
			font-size: 38px;
			font-size: calc-rem-value(38);
		}

		@include media-query(767px) {
			font-size: 28px;
			font-size: calc-rem-value(28);
		}
	}

	.btns>a:first-child {
		margin-right: 15px;
	}
}


/*--------------------------------------------------------------
#3.5	testimoninals-funfact-section
--------------------------------------------------------------*/
.testimoninals-funfact-section {
	padding-bottom: 0;

	.author-img img {
		width: auto;
		max-width: 100%;
	}

	.testimonials-slider-area .grid {
		background-color: #f9f6f0;
		padding: 130px 50px 50px;
		margin-top: 30px;
		position: relative;

		@include media-query(767px) {
			padding: 130px 10px 40px;
		}
	}

	.author {
		position: absolute;
		top: -30px;

		h5 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			margin: 1.3em 0 0.3em;
		}

		span {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: #797979;
		}
	}

	.testimonials-slider-area p {
		font-size: 18px;
		font-size: calc-rem-value(18);
		margin: 0;

		@include media-query(1199px) {
			font-size: 16px;
			font-size: calc-rem-value(16);
		}
	}

	.slick-arrow {
		background-color: $white;
		width: 45px;
		height: 45px;
		line-height: 45px;
		padding: 0;
		text-align: center;
		cursor: pointer;
		border-radius: 50%;
		box-shadow: 5px 27px 29px transparentize($black, 0.7);
		z-index: 11;
		top: auto;
		bottom: -80px;

		@include media-query(1199px) {
			margin: 5px;
		}

		@include media-query(991px) {
			float: left;
			display: none !important;
		}

		.fi:before {
			font-size: 20px;
			font-size: calc-rem-value(20);
			color: $theme-primary-color;
		}

		&.slick-prev {
			left: 0;

			&:before {
				font-family: "themify";
				content: "\e629";
				opacity: 1;
				color: $theme-primary-color;
				line-height: unset;
			}
		}

		&.slick-next {
			top: auto;
			right: auto;
			left: 55px;

			&:before {
				font-family: "themify";
				content: "\e628";
				opacity: 1;
				color: $theme-primary-color;
				line-height: unset;
			}
		}
	}

	/******************************
		fun-fact
	********************************/
	.fun-fact-grids>.grid+.grid {
		margin-top: 25px;
	}

	.fun-fact-area {
		margin-top: 50px;

		@include media-query(991px) {
			margin-top: 80px;
		}

		.info {
			overflow: hidden;

			@include media-query(991px) {
				max-width: 70%;
				margin: 0 auto;
			}

			@include media-query(550px) {
				max-width: 100%;
			}
		}

		.info>div {
			width: 50%;
			float: left;
		}

		.info>div:last-child {
			padding-left: 45px;

			@include media-query(550px) {
				padding-left: 0;
			}
		}

		h3 {
			font-size: 65px;
			font-size: calc-rem-value(65);
			margin: 0;
			width: 200px;
			text-align: right;
			-webkit-text-fill-color: $white;
			-webkit-text-stroke-width: 2px;
			-webkit-text-stroke-color: $heading-color;

			@include media-query(767px) {
				font-size: 45px;
				font-size: calc-rem-value(45);
			}

			@include media-query(550px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
				text-align: left;
			}
		}

		p {
			font-size: 16px;
			font-size: calc-rem-value(16);
			font-weight: 600;
			margin: 35px 0 0 0;
			border-bottom: 2px solid $text-color;
			text-transform: uppercase;

			@include media-query(767px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
				margin: 15px 0 0;
			}
		}
	}

	.odometer-value {
		font-family: $heading-font;
	}
}

.testimonials-pg-section {
	padding-bottom: 120px;

	.slick-slider {
		margin: -15px;

		.slick-slide {
			padding: 15px;
		}
	}

	.grid,
	.owl-theme .owl-controls .owl-nav [class*=owl-],
	.grid .author-img img {
		border-radius: 5px;
	}

	@include media-query(991px) {
		padding-bottom: 100px;
	}

	@include widther(768px) {
		.owl-theme .owl-controls {
			text-align: center;
		}

		.owl-theme .owl-controls .owl-nav [class*=owl-] {
			position: static;
			margin: 0 5px 0;
		}

	}

	@include widther(991px) {
		.owl-theme .owl-controls .owl-nav [class*=owl-] {
			position: static;
			margin: 15px 5px 0;
		}

	}
	.slick-arrow.slick-prev {
		left: 45%;
	}
	.slick-arrow.slick-next {
		left: 52%;
	}
}